import React from "react";
import { PdfViewer } from "../../component/pdf-viewer/PdfViewer";
import './WhitePaper.css';

/**
 * @author Christophe Convert
 * 
 */
export const WhitePaper = () : JSX.Element => {

    // adobeClientId={ADOBE_API_KEY} 
    // backupUrl="https://drive.google.com/file/d/1qYZLgglYY8LUlcn92rmn0faQ6ep-wTbh/view?usp=sharing"
    return (
        <>
        <div className="whitePaper-space" />
        <PdfViewer fileName="Jurakko-whitepaper.pdf" backupUrl="" adobeViewId="Perspective" />
        </>
    )
}